.center-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16vh;

}

.player-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 99;
  position: relative;
}

.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00E5FF;
  border-radius: 100px;
  border: 3px solid black;
  width: 100%;
  max-height: 310px;
  height: 33vh;
}

.player-control {
  display: flex;
  flex-direction: column;
  margin: 0 50px;
}

.play-pause-button {
  /* margin: 90px 50px; */
  cursor: pointer;
  height: 16vh;
}

.track-info {
  font-family: 'Muller';
  display: flex;
  flex-grow: 4;
  /* align-items: center; */
  justify-content: center;
  color: #1C4771;
  flex-direction: column;
}

.track-info-artist {
  display: flex;
  font-size: 6vh;
  font-weight: 900;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.track-info-title {
  display: flex;
  font-size: 3vh;
  font-weight: 300;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

#volume-control {
  background: transparent;
  display: flex;
  position: absolute;
  -webkit-appearance: none;
  width: 14%;
  padding: 20px 0px;
  margin-top: 150px;
}

#volume-control:focus {
  outline: none;
}

#volume-control::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: #1E4771;
  border: 1px solid black;
  border-radius: 90px;
}

#volume-control::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 15px;
  border: 1px solid;
  background-color: #EED14F;
  cursor: pointer;
  width: 19px;
  height: 19px;
}

.tab-list {
  display: flex;
  margin-left: 110px;
}

.tab {
  margin-left:-20px;
  padding: 5px 25px;
  height: 35px;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #00E5FF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-top: 3px solid black;
  border-left: 3px solid black;
  border-right: 3px solid black;
  font-family: 'Muller';
  font-size: 24pt;
  font-weight: 900;
  color: black;
  -webkit-text-fill-color: #FFFF00; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
}

.tab:hover{
  cursor: pointer;
}

.tab.active {
  background-color: #1C4771;
}

.air-radio {
  width: 300px;
  max-width: 300px;
}



.yandex-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items:flex-start;
  position: absolute;
  margin: 0 auto;
  z-index: 400;
  top: 0;
  width: 98%;
  max-height: 310px;
  height: 98%;
  border-radius: 100px;
  background-color: #12E7FF;
  text-align: center;
  color: #1C4771;
  font-family: 'Muller';
  text-transform: uppercase;
  visibility: hidden;
  margin: 3px;
}

.form-logo {
  margin-top: 50px;
}
.support-project {
  display: none;
  font-size: 27px;
}
.sum {
  text-align: left;
  margin-left: 10%;
  order: 1;
}
.comment {
  order: 3;
  flex: 100%;
}
.form-buttons {
  order: 4;
}
input[name=sum], textarea[name=comment] {
  width: 100%;
  padding: 1vh 20px;
  font-size: 20px;
  font-family: 'Muller';
  color: #1C4771;
  background: none;
  border: 2px solid #1C4771;
  border-radius: 30px;
  outline: none;
  margin-bottom: 20px;
}
.yandex-form span {
  font-size: 5vh;
  font-weight: bold;
  margin-bottom: 6px;
}
textarea {
  resize: none;
  height: 15vh;
}
textarea::-webkit-scrollbar {
  width: 0;
}
.form-buttons a {
  display: none;
}
.pay-var {
  align-items: flex-start;
  text-align: left;
  order: 2;
  margin-left: 10%;
}
.yandex-form a, .yandex-form button {
  color: inherit;
  text-decoration: none;
  border: 2px solid #1C4771;
  border-radius: 20px;
  outline: none;
  padding: 10px 15px;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
}
.yandex-form label {
  cursor: pointer;
}
.yandex-form input[type="radio"] {
  margin-bottom: 15px;
  cursor: pointer;
}
.yandex-form.active {
  visibility: visible;
}

.form-buttons button {
  font-family: 'Muller';
  color: black;
  font-size: 3vh;
  font-weight: 900;
  -webkit-text-fill-color: #FFFF00;
  -webkit-text-stroke-width: 1px;
  transform: rotate(6deg);
  font-style: italic;
  border: none;
}
.form-buttons button:hover, .form-buttons a:hover {
  -webkit-text-fill-color: #ffff009a;
}
.sum, .pay-var, .comment {
  margin-top: 5vh;
}
.form-buttons {
  background-color: #1C4771;
  border: 3px solid black;
  border-radius: 50%;
  font-style: italic;
  padding: 30px 10px;
  transform: rotate(-6deg);
  margin-top: 10vh;
}
.close-btn {
  font-family: 'Muller';
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  text-align: right;
  line-height: 70px;
  outline: none;
  top: -130px;
  right: 80px;
  font-size: 75px;
  color: black;
  -webkit-text-fill-color: #FFFF00;
  -webkit-text-stroke-width: 1px;
  -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.project-support {
  display: none;
  margin-left:-20px;
  padding: 5px 25px;
  height: 35px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  background-color: #00E5FF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-top: 3px solid black;
  border-left: 3px solid black;
  border-right: 3px solid black;
  font-family: 'Muller';
  font-size: 24pt;
  font-weight: 900;
  color: black;
  -webkit-text-fill-color: #FFFF00; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
}
.tab.none {
  display: none;
}
.pay-var {
  margin-top: 0;
}
@media (max-width: 1079px) {
  .close-btn {
    display: none;
  }
  .pay-var {
    margin-left: 0;
    text-align: center;
  }
  .project-support {
    height: auto;
    width: 100%;
    font-size: 13.5pt;
    margin-left: 0;
    text-align: center;
    padding: 0;
    max-height: 44px;
    height: 32px;
  }
  .support-project {
    display: block;
    font-size: 20px;
  }
  .form-logo {
    margin-top: 10px;
  }
  .form-logo img{
    width: 20%;
  }
  .support-project {
    margin: 10px auto;
    margin-bottom: 0;
  }
  .yandex-form {
    overflow-y: auto;
    color: #1C4771;
    display: block;
    margin: 0;
    border: none;
    width: 95%;
    max-height: 95%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top: 3px solid black;
  }
  .yandex-form span {
    font-size: 20px;
    font-weight: normal;
  }
  .sum, .comment {
    margin-left: 0;
    text-align: center !important;
  }
  .sum, .comment, .pay-var, .form-buttons {
    margin-top: 0;
  }
  input[name=sum], textarea[name=comment] {
    padding: 10px 15px;
    color: #1C4771;
    background: none;
  }
  textarea {
    height: auto;
  }
  .center-wrapper {
    margin: 60px auto 0;
  }
  .tab-list {
    max-width: 100%;
    margin: 0;
  }
  .tab {
    font-size: 13.5pt;
    width: 100%;
    text-align: center;
    max-height: 44px;
    box-sizing: border-box;
  }
  .form-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 0;
    font-style: normal;
    transform: rotate(0deg);
    margin: 0 auto;
    margin-top: 10px;
    background: none;
  }
  .form-buttons a {
    display: block;
    font-size: 15px;
  }
  .form-buttons button {
    border: 2px solid #1C4771;
    font-weight: normal;
    -webkit-text-fill-color: #fff;
    font-size: 20px;
    -webkit-text-stroke-width: 0;
    transform: rotate(0deg);
    font-style: normal;
    font-size: 15px;
  }

  .tab.active {
    border-top-right-radius: 15px;
    border-right: 3px solid black;
  }
  .player-wrapper {
    max-width: 100%;
  }
  .player {
    width: 100%;
    padding: 15% 0;
    max-height: fit-content;
    height: auto;
    flex-direction: column;
    border-radius: 30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-sizing: border-box;
  }
  .player-control {
    max-width: 130px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .track-info {
    margin: 10px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .track-info-artist {
    margin: 0 auto;
    display: block;
    font-size: 26pt;
    margin-bottom: 10px;
  }
  .track-info-title {
    font-size: 13.5pt;
  }
  #myata {
    margin-left: 0;
  }
  .form-buttons {
    color: #1C4771 !important;
  }
  .form-buttons button {
    -webkit-text-fill-color: #1C4771;
  }
}
@media (max-width: 1024px) {
  .player {
    padding: 15% 0;
  }
}
@media (max-width: 925px) {
  .form-logo {
    margin-top: 20px;
  }
  .support-project {
    margin: 20px auto;
  }
  .player {
    padding: 25% 0;
  }
}
@media (max-width: 768px) {
  .support-project {
    margin-top: 50px;
  }
}
@media (max-width: 414px) {
  .player {
    padding: 30% 0;
  }
  .support-project {
    margin-top: 25px;
  }
}
@media (max-width: 380px) {
  .support-project {
    margin-top: 15px;
  }
  .player {
    padding: 20% 0;
  }
  .footer_links img {
    width: 30% !important;
  }
  input[name=sum], textarea[name=comment] {
    padding: 10px 10px;
    margin-bottom: 15px;
  }
}
@media (max-width: 320px) {
  .player {
    padding: 15% 0;
  }
  .support-project {
    font-size: 20px;
  }
}

@media (max-width: 320px) {
  .player {
    padding: 15% 0;
  }
  .support-project {
    font-size: 20px;
  }
}
