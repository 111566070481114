.footer {
  margin-top: 102px;
  /*position: absolute;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  z-index: 100;
}
.footer {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(28, 71, 113, 0) 0%, rgba(17, 27, 37, 0.55) 40%, #111B25 106.38%);
  height: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 800;
}
.store_link {
  margin-right: 30px;
}
.store_link:last-child {
  margin-right: 0;
}
.footer_links {
  max-width: 33.3333%;
}
.donate_button {
  z-index: 10;
  text-align: center;
  cursor: pointer;
}
.donate_button img {
  width: 100px;
}
.footer_links img  {
  width: 90px !important;
}
.boosty_link {
  z-index: 10;
  text-align: right;
}
.boosty_link img{
  margin-bottom: 10px;
  width: 300px;
}

@media (max-width: 1079px) {
  .boosty_link img{
    margin-bottom: 0;
  }
  .footer {
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
  }
  .footer_links {
    margin-left: 10px;
    max-width: 20%;
  }
  .boosty_link {
    max-width: 115px;
  }
  .boosty_link img {
    width: 120%;
  }
  .footer_links img  {
    width: 40% !important;
  }
  .store_link {
    margin-right: 20px;
  }
  .donate_button img {
    width: 100%;
  }
  .boosty_link {
    margin-right: 10px;
  }
  input[name=sum], textarea[name=comment] {
    width: 80%;
  }
  .form-buttons {
    max-width: 80%;
  }
  .sum {
    margin-top: 30px;
  }
}

@media (min-width: 1079px) {
  .sum span {
    margin-left: 40px;
  }

  .comment span {
    margin-left: 40px;
  }
}

@media (max-width: 768px) {
  .footer_links {
    margin-left: 5%;
  }
  .boosty_link {
    margin-right: 5%;
  }
  .footer_links img  {
    width: 39% !important;
  }
}
@media (max-width: 485px) {
  .store_link {
    margin-right: 10px;
  }
  .footer_links img  {
    width: 38% !important;
  }
}
@media (max-width: 411px) {
  .footer_links {
    margin-left: 3%;
  }
  .boosty_link {
    margin-right: 3%;
  }
  .footer_links img  {
    width: 43% !important;
  }
}
@media (max-width: 388px) {
  .footer_links img  {
    width: 43% !important;
  }
  .footer_links {
    margin-left: 10px;
    max-width: 25%;
  }
}
