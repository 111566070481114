.header {
  background-color: #00E5FF;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.5);
}

.header .container {
  position: relative;
  background-color: #00E5FF;
  height: 120px;
  display: flex;
  justify-content: space-between;
}

.icon-layout {
  display: flex;
  order: 2;
  height: 100%;
  justify-content: flex-end;
}

.soc-icon {
  display: flex;
  margin-left: 20px;
  padding: 0;
  height: 100%;
}
.soc-icon img{
  width: 73px;
}
.nav {
  display: none;
}
#logo {
  z-index: 1000;
  width: 200px;
  margin-top: 15px;
  object-fit: cover;

}
#logo.spin{
  animation: spin_delay 5s linear infinite;
}

@keyframes spin_delay {
  0% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 0deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
@media ( max-width: 1079px ) {
  .header {
    width: 100%;
    padding: 0;
    background-color: #00E5FF;
  }
  .header .container {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    max-height: 70px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.5);
  }
  #logo {
    max-width: 105px !important;
    width: 105px !important;
    height: 105px !important;
    margin-top: 18px;
    height: auto;
    z-index: 400;
    position: absolute;
    left: 5%
  }
  .icon-layout {
    display: none;
  }
  .nav {
    display: flex;
    flex-direction: column;
    z-index: 200;
    width: 100%;
  }
  .nav p {
    min-height: 49px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Muller';
    font-size: 13.5pt;
    font-weight: 900;
    color: black;
    -webkit-text-fill-color: #FFFF00;
    -webkit-text-stroke-width: 1px;
    margin: 0;

    margin: 10px 0;
    margin-right: 30px;
  }
  .nav p span:first-child {
    margin: 15px 0;
  }
  .nav p span:last-child {
    display: none;
  }
  .menu_button.active span:first-child {
    display: none;
  }
  .menu_button.active span:last-child {
    display: inline;
    font-size: 50pt;
    font-weight: 600;
    margin-right: -15px;
  }
  .nav ul {
    width: 100%;
    background-color: #00E5FF;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    text-align: center;
    height: 0;
    padding: 0 10px;
    transition: .5s;
    visibility: hidden;
    flex-wrap: wrap;
  }
  .nav ul li {
    visibility: hidden;
    height: 0;
  }
  .nav ul.active li {
    visibility: visible;
    min-height: 52px;
  }
  .nav ul.active {
    visibility: visible;
    min-height: 49px;
    padding: 80px 0 40px;
    box-shadow: 0px 20px 15px rgba(0,0,0,0.5);
  }

  .soc-icon {
    margin-left: 0;
  }

  .soc-icon img {
    margin: 0 auto;
    max-width: 54px !important;
  }
}
