html {
  height: 100%;
}
.container {
  max-width: 1234px;
  margin: 0 auto;
}
body {
  height: 100vh;
  background-color: #232122;
  margin: 0;
  background-image: url(./images/bg1.png);
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  font-family: 'Muller';
}
#root {
  height: 100vh;
  position: relative;
  overflow-y: hidden;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

button {
  margin: 0;
}

@font-face {
  font-family: 'Muller';
  font-weight: 900;
  src: local('Muller'), url(./fonts/muller_heavy.otf) format('opentype');
}

@font-face {
  font-family: 'Muller';
  font-weight: 400;
  src: local('Muller'), url(./fonts/muller_regular.ttf) format('truetype');
}


@media (max-width: 1633px) {
  .container {
    padding: 0 15px;
  }
}
